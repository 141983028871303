import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import Autolinker from 'autolinker';
class LinkyPipe {
  transform(value, options) {
    return Autolinker.link(value, options);
  }
}
LinkyPipe.ɵfac = function LinkyPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinkyPipe)();
};
LinkyPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "linky",
  type: LinkyPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkyPipe, [{
    type: Pipe,
    args: [{
      name: 'linky'
    }]
  }], null, null);
})();
class LinkyModule {}
LinkyModule.ɵfac = function LinkyModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinkyModule)();
};
LinkyModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LinkyModule
});
LinkyModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkyModule, [{
    type: NgModule,
    args: [{
      declarations: [LinkyPipe],
      exports: [LinkyPipe]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-linky
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LinkyModule, LinkyPipe };
